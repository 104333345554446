import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import VueBarcode from 'vue-barcode'
import { StreamBarcodeReader } from 'vue-barcode-reader'

Vue.use(VueApexCharts)

const Auth = () => import(/* webpackChunkName: "auth" */ '@/components/Auth.vue')
const Toast = () => import(/* webpackChunkName: "toast" */ '@/components/Toast.vue')
const Tags = () => import(/* webpackChunkName: "tags" */ '@/components/Tags.vue')
const FeatureBanner = () => import(/* webpackChunkName: "feature-banner" */ '@/components/features/Banner.vue')
const XlsxImport = () => import(/* webpackChunkName: "xlsx-import" */ '@/components/XlsxImport.vue')
const XlsxPricingImport = () => import(/* webpackChunkName: "xlsx-pricing-import" */ '@/components/XlsxPricingImport.vue')
const XlsxExport = () => import(/* webpackChunkName: "xlsx-export" */ '@/components/XlsxExport.vue')
const TrackStatus = () => import(/* webpackChunkName: "track-status" */ '@/components/TrackStatus.vue')
const PickupData = () => import(/* webpackChunkName: "pickup-data" */ '@/components/PickupData.vue')
const FinanceMoneyIn = () => import(/* webpackChunkName: "finance-money-in" */ '@/components/finance/MoneyIn.vue')
const FinanceVendorInvoices = () => import(/* webpackChunkName: "finance-vendor-invoices" */ '@/components/finance/VendorInvoices.vue')
const FinanceAccurate = () => import(/* webpackChunkName: "finance-accurate" */ '@/components/finance/Accurate.vue')
const Dispatch = () => import(/* webpackChunkName: "dispatch" */ '@/components/Dispatch.vue')
const DispatchMain = () => import(/* webpackChunkName: "dispatch-main" */ '@/components/DispatchMain.vue')
const FinanceDownload = () => import(/* webpackChunkName: "finance-download" */ '@/components/finance/Download.vue')
const Download = () => import(/* webpackChunkName: "download" */ '@/components/Download.vue')
const BusinessTTK = () => import(/* webpackChunkName: "business-ttk" */ '@/components/BusinessTTK.vue')
const BusinessTTKContent = () => import(/* webpackChunkName: "business-ttk-content" */ '@/components/BusinessTTKContent.vue')
const BusinessXlsxExport = () => import(/* webpackChunkName: "business-xlsx-export" */ '@/components/BusinessXlsxExport.vue')
const XlsxAwbImport = () => import(/* webpackChunkName: "business-xlsx-awb-import" */ '@/components/XlsxAwbImport.vue')
const DialogPlan = () => import(/* webpackChunkName: "dialog-plan" */ '@/components/DialogPlan.vue')
const DialogPrintPDF = () => import(/* webpackChunkName: "dialog-print-pdf" */ '@/components/DialogPrintPDF.vue')

const CommerceOrder = () => import(/* webpackChunkName: "commerce-order" */ '@/components/commerce/order.vue')
const CommerceProduct = () => import(/* webpackChunkName: "commerce-product" */ '@/components/commerce/product.vue')

Vue.component('apexchart', VueApexCharts)
Vue.component('StreamBarcodeReader', StreamBarcodeReader)
Vue.component('Auth', Auth)
Vue.component('Toast', Toast)
Vue.component('Tags', Tags)
Vue.component('FeatureBanner', FeatureBanner)
Vue.component('XlsxPricingImport', XlsxPricingImport)
Vue.component('XlsxImport', XlsxImport)
Vue.component('XlsxExport', XlsxExport)
Vue.component('TrackStatus', TrackStatus)
Vue.component('PickupData', PickupData)
Vue.component('FinanceMoneyIn', FinanceMoneyIn)
Vue.component('FinanceVendorInvoices', FinanceVendorInvoices)
Vue.component('FinanceAccurate', FinanceAccurate)
Vue.component('FinanceDownload', FinanceDownload)
Vue.component('Dispatch', Dispatch)
Vue.component('DispatchMain', DispatchMain)
Vue.component('Download', Download)
Vue.component('BusinessTTK', BusinessTTK)
Vue.component('BusinessTTKContent', BusinessTTKContent)
Vue.component('BusinessXlsxExport', BusinessXlsxExport)
Vue.component('DialogPlan', DialogPlan)
Vue.component('DialogPrintPDF', DialogPrintPDF)
Vue.component('XlsxAwbImport', XlsxAwbImport)

Vue.component('CommerceOrder', CommerceOrder)
Vue.component('CommerceProduct', CommerceProduct)
Vue.component('barcode', VueBarcode)
